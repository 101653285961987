import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { ALERT_REDUX_ACTIONS } from '../util/AlertManager';
import { useDispatch } from 'react-redux';
import FormatManager from '../util/FormatManager';
import DashboardApi from '../api/DashboardApi';
import BalanceChart from '../fragment/dashboard/BalanceChart';
import TopListView from '../fragment/dashboard/TopListView';
import AppointmentTable from '../fragment/dashboard/AppointmentTable';
import SummaryCard from '../fragment/dashboard/SummaryCard';

const styles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 1),
    },

    card: {
        width: '100%',
    },
    cardContainer: {
        padding: theme.spacing(1),
    },
}));

export const TODAY_APPOINTMENT_TABLE_FIELDS = [
    {
        name: 'patient',
        label: 'Patient',
        type: 'raw',
        sortable: false,
        onLoad: (data, rowIdx) => {
            let name = data.patient?.fullName;
            const phones = data.patient?.phones;
            let phone = '';
            if (phones.length > 0) {
                let phIdx = data.patient?.phones?.findIndex((p) => p.primaryNumber);
                if (phIdx < 0) {
                    phIdx = 0;
                }
                phone = phones[phIdx].phoneNumber;
            }
            return (
                <span>
                    {name} (
                    <a onClick={(e) => e.preventDefault()} href={`tel:${phone}`}>
                        {phone}
                    </a>
                    )
                </span>
            );
        },
    },
    {
        name: 'doctor',
        align: 'left',
        label: 'Doctor',
        type: 'raw',
        sortable: false,
        onLoad: (data, rowIdx) => data.doctor?.fullName,
    },
    {
        name: 'fromTime',
        align: 'center',
        label: 'From',
        sortable: false,
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.fromTime, 'HH:MM'),
    },
    {
        name: 'status',
        label: 'Status',
        sortable: false,
    },
];

export const UPCOMING_APPOINTMENT_TABLE_FIELDS = [
    {
        name: 'patient',
        label: 'Patient',
        type: 'raw',
        sortable: false,
        onLoad: (data, rowIdx) => {
            let name = data.patient?.fullName;
            const phones = data.patient?.phones;
            let phone = '';
            if (phones.length > 0) {
                let phIdx = data.patient?.phones?.findIndex((p) => p.primaryNumber);
                if (phIdx < 0) {
                    phIdx = 0;
                }
                phone = phones[phIdx].phoneNumber;
            }
            return (
                <span>
                    {name} (
                    <a onClick={(e) => e.preventDefault()} href={`tel:${phone}`}>
                        {phone}
                    </a>
                    )
                </span>
            );
        },
    },
    {
        name: 'doctor',
        align: 'left',
        label: 'Doctor',
        type: 'raw',
        sortable: false,
        onLoad: (data, rowIdx) => data.doctor?.fullName,
    },
    {
        name: 'date',
        align: 'center',
        label: 'From',
        sortable: false,
        onLoad: (data, rowIdx) => `${FormatManager.formatDate(data.date, 'dd LLL')} (${FormatManager.formatDate(data.fromTime, 'HH:MM')})`,
    },
    {
        name: 'status',
        label: 'Status',
        sortable: false,
    },
];

const TODAY = new Date();

const Dashboard = (props) => {
    const classes = styles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const [todaySummary, setTodaySummary] = useState({});
    const [chartData, setChartData] = useState([]);
    const [topDoctors, setTopDoctors] = useState([]);
    const [topPatients, setTopPatients] = useState([]);
    const [topTreatments, setTopTreatments] = useState([]);
    const [todayPaging, setTodayPaging] = useState({
        currentPage: 0,
        pageSize: 10,
        total: 0,
        data: [],
    });
    const [upcomingPaging, setUpcomingPaging] = useState({
        currentPage: 0,
        pageSize: 10,
        total: 0,
        data: [],
    });

    const handleError = (error) => {
        console.warn('Dashboard Error => ', error);
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const loadTodaySummary = async () => {
        try {
            const result = await DashboardApi.getTodaySummary(TODAY);
            console.log('Today summary => ', result);
            setTodaySummary(result);
        } catch (error) {
            handleError(error);
        }
    };

    const loadTodayAppointments = async (currentPage, pageSize) => {
        try {
            const result = await DashboardApi.getAppointments('t', TODAY.getTime(), TODAY.getTime(), currentPage, pageSize);
            setTodayPaging(result);
        } catch (error) {
            handleError(error);
        }
    };

    const loadUpcomingAppointments = async (currentPage, pageSize) => {
        try {
            const fromDate = new Date(TODAY.getTime());
            fromDate.setDate(TODAY.getDate() + 1);

            const toDate = new Date(TODAY.getTime());
            toDate.setDate(TODAY.getDate() + 3);

            const result = await DashboardApi.getAppointments('u', fromDate.getTime(), toDate.getTime(), currentPage, pageSize);
            setUpcomingPaging(result);
        } catch (error) {
            handleError(error);
        }
    };

    const loadBalance = async (type) => {
        try {
            const params = type === 'w' ? { lastDay: TODAY.getTime() } : { year: TODAY.getFullYear() };
            const result = await DashboardApi.getBalance(type, params);
            setChartData(result);
        } catch (error) {
            handleError(error);
        }
    };

    const loadTopDoctors = async () => {
        try {
            const result = await DashboardApi.getTopDoctors(TODAY);
            setTopDoctors(result.data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadTopPatients = async () => {
        try {
            const result = await DashboardApi.getTopPatients(TODAY);
            setTopPatients(result.data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadTopTreatments = async () => {
        try {
            const result = await DashboardApi.getTopTreatments(TODAY);
            setTopTreatments(result.data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadData = async () => {
        try {
            dispatch({
                type: ALERT_REDUX_ACTIONS.SHOW_LOADING,
            });
            //Load Today Income
            await loadTodaySummary();

            //Chart Data
            await loadBalance('w');

            //Today Appointments
            await loadTodayAppointments(0);

            //Upcoming Appointments
            await loadUpcomingAppointments(0);

            //Load Top Doctors
            await loadTopDoctors();

            //Load Top Patients
            await loadTopPatients();

            //Load Top Treatments
            await loadTopTreatments();
            dispatch({
                type: ALERT_REDUX_ACTIONS.HIDE,
            });
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
        //eslint-disable-next-line
    }, []);

    const handleChartTypeChange = (type) => {
        loadBalance(type);
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column" spacing={3}>
                <Grid container direction="row">
                    <Grid item container lg={5} md={12} className={classes.cardContainer}>
                        <AppointmentTable
                            title="Today Appointment"
                            paging={todayPaging}
                            fields={TODAY_APPOINTMENT_TABLE_FIELDS}
                            onRowClick={(item, idx) => history.push(`/appointment/detail/${item.id}`)}
                        />
                    </Grid>
                    <Grid item container lg={7} md={12} className={classes.cardContainer}>
                        <AppointmentTable
                            title="Upcoming Appointment"
                            paging={upcomingPaging}
                            fields={UPCOMING_APPOINTMENT_TABLE_FIELDS}
                            onRowClick={(item, idx) => history.push(`/appointment/detail/${item.id}`)}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item container lg={8} md={8} sm={12}>
                        <Grid item container direction="row">
                            <Grid item container lg={4} md={4} sm={12} className={classes.cardContainer}>
                                <SummaryCard
                                    icon="event_available"
                                    iconColor={theme.palette.warning.main}
                                    title="Appointments"
                                    data={todaySummary.appointmentCount}
                                />
                            </Grid>
                            <Grid item container lg={4} md={4} sm={12} className={classes.cardContainer}>
                                <SummaryCard
                                    iconColor={theme.palette.success.main}
                                    icon="receipt_long"
                                    title="Voucher Amount"
                                    data={FormatManager.formatNumeral(todaySummary.voucherAmount, '0,0') + ' MMK'}
                                />
                            </Grid>
                            <Grid item container lg={4} md={4} sm={12} className={classes.cardContainer}>
                                <SummaryCard
                                    iconColor={theme.palette.info.main}
                                    icon="account_balance_wallet"
                                    title="Income"
                                    data={FormatManager.formatNumeral(todaySummary.income, '0,0') + ' MMK'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="flex-end" className={classes.cardContainer}>
                            <BalanceChart startDate={TODAY} data={chartData} onTypeChange={handleChartTypeChange} />
                        </Grid>
                    </Grid>
                    <Grid item container lg={4} md={4} sm={12} direction="column">
                        <Grid item container className={classes.cardContainer}>
                            <TopListView
                                className={classes.card}
                                data={topDoctors}
                                title={'Doctors of the month'}
                                onLoadImage={(item, index) => item.profileImage}
                                onLoadPrimary={(item, index) => item.fullName}
                                onLoadSecondary={(item, index) => {
                                    const amount = FormatManager.formatNumeral(item.amount, '0,0');
                                    return amount + ' MMK';
                                }}
                                onItemClick={(item) => history.push(`/doctor/detail/${item.id}`)}
                            />
                        </Grid>
                        <Grid item container className={classes.cardContainer}>
                            <TopListView
                                className={classes.card}
                                data={topPatients}
                                title={'Top patients'}
                                onLoadImage={(item, index) => item.profileImage}
                                onLoadPrimary={(item, index) => item.fullName}
                                onLoadSecondary={(item, index) => {
                                    const amount = FormatManager.formatNumeral(item.amount, '0,0');
                                    return amount + ' MMK';
                                }}
                                onItemClick={(item) => history.push(`/patient/detail/${item.id}`)}
                            />
                        </Grid>
                        <Grid item container className={classes.cardContainer}>
                            <TopListView
                                className={classes.card}
                                data={topTreatments}
                                title={'Top treatments'}
                                onLoadImage={(item, index) => item.image}
                                onLoadPrimary={(item, index) => item.name}
                                onLoadSecondary={(item, index) => {
                                    const amount = FormatManager.formatNumeral(item.amount, '0,0');
                                    return amount + ' MMK';
                                }}
                                onItemClick={(item) => history.push(`/treatmentType/detail/${item.id}`)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Dashboard);

import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Container, Icon, Grid, Button, Paper, makeStyles } from '@material-ui/core';

import { AVAILABLE_CURRENICES } from '../../config/Constant';
import FormatManager from '../../util/FormatManager';
import { useDispatch } from 'react-redux';
import { ALERT_REDUX_ACTIONS } from '../../util/AlertManager';
import { FLASH_REDUX_ACTIONS } from '../../util/FlashManager';
import { ImageInput, TextInput } from '../../component/control';
import DataTable from '../../component/table';
import PaymentApi from '../../api/PaymentApi';

export const PAYMENT_TABLE_FEILDS = [
    {
        name: 'id',
        align: 'right',
        label: 'No.',
        onLoad: (data, rowIdx) => rowIdx + 1,
    },
    {
        name: 'paymentDate',
        align: 'center',
        label: 'Date',
        onLoad: (data, rowIdx) => FormatManager.formatDate(data.date, 'dd MMM yyyy'),
    },
    {
        name: 'description',
        label: 'Description',
    },
    {
        name: 'amount',
        align: 'right',
        label: 'Amount',
        onLoad: (data, rowIdx) => FormatManager.thousandSeparator(data.amount.price) + ' ' + data.amount.currency ?? 'MMK',
    },
];

const styles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formHeader: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    formFooter: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    infoGrid: {
        width: '100%',
    },
    infoRow: {
        padding: theme.spacing(1, 0),
    },
    infoLabel: {
        padding: theme.spacing(2, 0, 0),
        fontWeight: 'bolder',
        width: 120,
    },
    infoSeperator: {
        padding: theme.spacing(2, 0, 0),
        width: theme.spacing(2),
    },
    infoValue: {
        textAlign: 'left',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        padding: theme.spacing(2, 0, 0),
    },
    footerLabel: {
        fontWeight: 'bolder',
        padding: theme.spacing(2, 0, 0),
        width: 150,
    },
    tableInput: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontWeight: 'bolder',
        fontSize: '1em',
        padding: theme.spacing(2, 0, 0),
        width: '100%',
        color: theme.palette.type === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark,
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            color: theme.palette.action.disabled,
        },
    },
}));

const DEFAULT_AMOUNT = {
    exchangeRate: 1,
    currency: 'MMK',
    price: 0,
};

const VoucherPayment = (props) => {
    const classes = styles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [voucherCode, setVoucherCode] = useState('');
    const [voucher, setVoucher] = useState(null);
    const [payments, setPayments] = useState([]);
    const [form, setForm] = useState({});
    const [amount, setAmount] = useState(DEFAULT_AMOUNT);
    const [net, setNet] = useState(0);
    const [debt, setDebt] = useState(0);

    const init = () => {
        setVoucherCode('');
        setNet(0);
        setDebt(0);
        setAmount(DEFAULT_AMOUNT);
        setForm({});
        setVoucher(null);
        setPayments([]);
    };

    const handleError = (error) => {
        dispatch({
            type: ALERT_REDUX_ACTIONS.SHOW,
            alert: error || 'Please check your internet connection and try again.',
        });
    };

    const calculateAmount = () => {
        let t = 0; //t for Total

        //Collect all treatment amount
        if (voucher?.treatments) {
            voucher.treatments.forEach((treatment) => {
                t += parseFloat(treatment?.price ?? 0) * parseFloat(treatment?.exchangeRate ?? 1);
            });
        }

        //Collect all voucher item amount
        if (voucher?.items) {
            voucher.items.forEach((item) => {
                t += parseFloat(item?.price ?? 0) * parseFloat(item?.exchangeRate ?? 1) * parseInt(item.qty ?? 1);
            });
        }

        const v = t - voucher?.discountAmount ?? 0; //v for voucher net amount
        setNet(v);
        const d = (v - voucher?.paidAmount) / amount?.exchangeRate; //d for debt
        setDebt(d);
    };

    useEffect(() => {
        if (voucherCode && voucher) {
            calculateAmount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, voucher]);
    
    const preventArrowKey = (event) => {
        //prevent down arrow and up arrow
        if([38, 40].includes(event.keyCode)){
            event.preventDefault();
        }
    }

    const handleCheckVoucher = async () => {
        try {
            const response = await PaymentApi.getPaymentsByCode(voucherCode);
            setVoucher(response.voucher);
            setPayments(response.payments);
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmit = async () => {
        if (!window.navigator.onLine) {
            handleError('Please check your internet connection and try again.');
            return;
        }

        if (amount.price <= 0) {
            handleError('Please enter current paid amount.');
            return;
        }

        if (amount.exchangeRate <= 0) {
            handleError('Invalid exchange rate.');
            return;
        }

        if (!form.description || form.description.length === 0) {
            handleError('Please enter payment description.');
            return;
        }

        try {
            const receive = {
                refId: voucher.id,
                description: form.description,
                amount: amount,
            };

            //Upload Attachment1
            if (form.tempAttach1 && form.tempAttach1.id) {
                receive.attachment1 = form.tempAttach1;
            } else if (form.tempAttach1) {
                const fileResponse = await PaymentApi.fileUpload(form.tempAttach1, 'attachment1');
                if (fileResponse) {
                    receive.attachment1 = fileResponse;
                }
            } else {
                receive.attachment1 = null;
            }

            //Upload Attachment2
            if (form.tempAttach2 && form.tempAttach2.id) {
                receive.attachment2 = form.tempAttach2;
            } else if (form.tempAttach2) {
                const fileResponse = await PaymentApi.fileUpload(form.tempAttach2, 'attachment2');
                if (fileResponse) {
                    receive.attachment2 = fileResponse;
                }
            } else {
                receive.attachment2 = null;
            }

            //Upload Attachment3
            if (form.tempAttach3 && form.tempAttach3.id) {
                receive.attachment3 = form.tempAttach3;
            } else if (form.tempAttach3) {
                const fileResponse = await PaymentApi.fileUpload(form.tempAttach3, 'attachment3');
                if (fileResponse) {
                    receive.attachment3 = fileResponse;
                }
            } else {
                receive.attachment3 = null;
            }

            dispatch({ type: ALERT_REDUX_ACTIONS.SHOW_LOADING });

            const receive_pdf = await PaymentApi.payAndPrintReceive(receive);
            const print_url = URL.createObjectURL(receive_pdf);

            const print_window = window.open(print_url, '_blank');
            print_window.focus();
            // printJS({
            //     printable: receive_pdf,
            //     type: 'pdf',
            //     showModal: true,
            //     base64: true,
            //     documentTitle: `voucher_receive`,
            //     modalMessage: 'Printing ...',
            //     onError: (error) => {
            //         console.warn('Error => ', error);
            //         handleError('Invalid Voucher Printing!', error);
            //     },
            // });

            dispatch({ type: ALERT_REDUX_ACTIONS.HIDE });
            dispatch({
                type: FLASH_REDUX_ACTIONS.SHOW,
                flash: { type: 'success', message: `Thank you for your payment!` },
            });
            init();
        } catch (error) {
            handleError(error);
        }
    };

    //Build Branch Info with Transfer
    let branchInfo = voucher?.branch?.code || '';
    if (voucher?.appointment?.transferBranch) {
        branchInfo = voucher?.appointment?.transferBranch?.code + ' --> ' + voucher?.branch?.code;
    }

    //Combine Appointment Doctor and Treatment Doctor
    let doctorInfo = voucher?.doctor?.fullName || '';
    if (voucher?.appointment?.treatmentDoctor?.id && voucher?.doctor?.id !== voucher?.appointment?.treatmentDoctor?.id) {
        doctorInfo += `(${voucher?.appointment?.treatmentDoctor.fullName})`;
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Paper className={classes.paper} elevation={3}>
                    <Grid container>
                        <TextInput
                            id="code"
                            icon="code"
                            label="Voucher No."
                            value={voucherCode}
                            onKeyDown={(event) => {
                                if (event.keyCode === 13) {
                                    handleCheckVoucher();
                                }
                            }}
                            onChange={(event) => setVoucherCode(event.target.value)}
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Branch</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{branchInfo}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Doctor</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{doctorInfo}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>Date</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{FormatManager.formatDate(voucher?.date, 'dd MMM, yyyy')}</th>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel}>patient</td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <th className={classes.infoValue}>{voucher?.patient?.fullName}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid className={classes.formHeader} container>
                        <TextInput id="note" label="Voucher Note" value={voucher?.note} multiline={true} rows={3} disabled={true} />
                    </Grid>
                    <Grid container>
                        <DataTable disablePaging={true} items={payments} fields={PAYMENT_TABLE_FEILDS} />
                    </Grid>
                    <Grid direction="row" spacing={3} container className={classes.formFooter}>
                        <Grid lg={7} md={7} sm={12} xs={12} item container alignItems="flex-start" alignContent="flex-start">
                            <TextInput
                                id="description"
                                label="Description"
                                value={form?.description}
                                multiline={true}
                                rows={3}
                                onChange={(event) => {
                                    setForm({ ...form, description: event.target.value });
                                }}
                            />
                            <Grid direction="row" spacing={3} container>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach1"
                                        enableFilePicker={false}
                                        guild="ACCOUNT"
                                        value={form?.tempAttach1}
                                        onChange={(event) => setForm({ ...form, tempAttach1: event.target.value })}
                                    />
                                </Grid>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach2"
                                        guild="ACCOUNT"
                                        enableFilePicker={false}
                                        value={form?.tempAttach2}
                                        onChange={(event) => setForm({ ...form, tempAttach2: event.target.value })}
                                    />
                                </Grid>
                                <Grid lg={4} md={4} sm={12} xs={12} item container justifyContent="center">
                                    <ImageInput
                                        size={{ width: 150, height: 120 }}
                                        id="tempAttach3"
                                        guild="ACCOUNT"
                                        enableFilePicker={false}
                                        value={form?.tempAttach3}
                                        onChange={(event) => setForm({ ...form, tempAttach3: event.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} md={5} sm={12} xs={12}>
                            <table className={classes.infoGrid}>
                                <tbody>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Voucher Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                disabled={true}
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(net ?? 0) + ' MMK'}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Total Paid
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                disabled={true}
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(voucher?.paidAmount ?? 0) + ' MMK'}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Debt Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                disabled={true}
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(debt) + ' ' + amount?.currency ?? 'MMK'}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Currency
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <select
                                                className={classes.tableInput}
                                                onChange={(event) => setAmount({ ...amount, currency: event.target.value })}
                                                value={amount?.currency}
                                            >
                                                {AVAILABLE_CURRENICES.map((item) => (
                                                    <option key={item.code} value={item.code}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Ex. Rate
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                id="exchangeRate"
                                                className={classes.tableInput}
                                                type="number"
                                                value={amount?.exchangeRate || ''}
                                                onKeyDown={preventArrowKey}
                                                onChange={(event) => setAmount({ ...amount, exchangeRate: event.target.value })}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.infoLabel} align="right">
                                            Current Paid
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                id="price"
                                                className={classes.tableInput}
                                                type="number"
                                                onKeyDown={preventArrowKey}
                                                value={amount?.price || ''}
                                                onChange={(event) => setAmount({ ...amount, price: event.target.value })}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={classes.infoRow}>
                                        <td className={classes.footerLabel} align="right">
                                            Left Amount
                                        </td>
                                        <td className={classes.infoSeperator}>:</td>
                                        <td>
                                            <input
                                                className={classes.tableInput}
                                                type="text"
                                                value={FormatManager.thousandSeparator(debt - amount?.price ?? 0) + ' ' + amount?.currency}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Grid justifyContent="flex-end" container>
                        <Button type="button" variant="contained" color="primary" onClick={() => handleSubmit()}>
                            <Icon color="action">save</Icon> Save
                        </Button>
                    </Grid>
                </Paper>
            </Container>
        </>
    );
};

export default withRouter(VoucherPayment);
